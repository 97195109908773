import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dummyData from "./dummyData";
import "./progress.css";

const ProgressPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!location.state) {
        // Redirect or show an error if no state is found
        return <div>No course data available</div>;
    }

    const { name, currentIndex } = location.state;
    const matchedData = dummyData.find((data) => data.name === name);

    if (!matchedData) {
        // Redirect or show an error if no data is found
        return <div>No course data found</div>;
    }

    const totalSteps = matchedData.steps.length;
    const stepPercentage = totalSteps > 1 ? (currentIndex / (totalSteps - 1)) * 100 : 100;

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <div className="progress-container">
            <button className="close-button" onClick={handleBackClick}>X</button>
            <div className="timeline">
                <div className="timeline-progress" style={{ width: `${stepPercentage}%` }}></div>
                <div className="timeline-milestones">
                    {[0, 25, 50, 75, 100].map((milestone) => (
                        <div
                            key={milestone}
                            className="timeline-milestone"
                            style={{ left: `${milestone}%` }}
                        >
                            <span>{milestone}%</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProgressPage;
