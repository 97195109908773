const moveRecorder = (gameInstance, openingMoves) => {
    let moveIndex = 0;  // Track the move number
    let previousPosition = gameInstance.fen();  // Store the initial position (starting state)
  
    // Function to format board position into an object
    function formatBoardPosition(board) {
      const positionObj = {};
      const files = 'abcdefgh'; // Files of the chessboard
      const ranks = '12345678'; // Ranks of the chessboard
    
      for (let rank = 0; rank < 8; rank++) {
        for (let file = 0; file < 8; file++) {
          const square = files[file] + ranks[7 - rank]; // e.g., 'a1', 'b2', etc.
          const piece = board[rank][file];
          if (piece) {
            positionObj[square] = piece.color + piece.type.toUpperCase(); // 'wP' for white pawn, 'bK' for black king, etc.
          } else {
            positionObj[square] = null; // Empty square
          }
        }
      }
      return positionObj;
    }
  
    // Function to check if the move matches the expected opening move
    function checkOpeningMove(move) {
      const expectedMove = openingMoves[moveIndex];
      if (move === expectedMove) {
        return true;
      }
      return false;
    }
  
    // Function to handle the move
    function handleMove(move) {
      // Check if the move matches the expected opening move
      if (checkOpeningMove(move)) {
        console.log('Correct move:', move);
        moveIndex++;  // Move to the next expected move
      } else {
        console.log('Wrong move! Resetting to previous position.');
        gameInstance.load(previousPosition);  // Reset to the last valid position
        // Find the moved piece and send it back to its starting position
        resetPieceToStart(move);
      }
    }
  
    // Function to reset the piece back to its starting position
    function resetPieceToStart(move) {
      // You can adjust this to match specific pieces and their starting positions
      // For example, if move is "e4", reset the pawn to d2
      const resetMoves = {
        "e4": "e2",  // Reset pawn on e4 to e2
        "d4": "d2",  // Reset pawn on d4 to d2
        // Add more reset rules for other moves if needed
      };
  
      if (resetMoves[move]) {
        gameInstance.move({ from: move, to: resetMoves[move] });
        console.log(`Piece moved back to: ${resetMoves[move]}`);
      }
    }
  
    // Function to handle a move being made
    function handleMoveAndTrack(move) {
      previousPosition = gameInstance.fen();  // Store current position before making a move
      gameInstance.uglyMove(move);  // Make the move
      handleMove(move);  // Check if the move is correct
    }
  
    // Function to show the current board position
    function clickShowPositionBtn() {
      console.log("Current position as an Object:");
      const boardPosition = gameInstance.board(); // Get the board as a 2D array
      const formattedPosition = formatBoardPosition(boardPosition);
      console.log(formattedPosition);
  
      console.log("Current position as a FEN string:");
      console.log(gameInstance.fen());
    }
  
    // Return the functions you need
    return { 
      clickShowPositionBtn,
      handleMoveAndTrack
    };
  };
  
  export default moveRecorder;
  